
.contador{
  
  color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  //height: 100vh;
  overflow: hidden;
  margin: 0;

 
  
}

.counter-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 30px 50px;

  span{
    color: $dark;
    font-weight: $bold;
  }
}

.counter-container{
  i{
    color: $dark;
  }
}



.counter {
  font-size: 60px;
  margin-top: 10px;
}

@media (max-width: 580px) {
  body {
    flex-direction: column;
  }
}
