.py-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.my-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 30px;
    bottom: 30px;
    z-index: 99;
}


/*** Spinner ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}


/*** Button ***/
.btn {
    font-weight: 500;
    transition: .5s;
}

.btn.btn-primary,
.btn.btn-outline-primary:hover {
    color: #FFFFFF;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
}


/*** Navbar ***/
.logo{
    img{
        width: 15rem;
    }
}

.navbar.sticky-top {
    top: -100px;
    transition: .5s;
}

.navbar .navbar-brand,
.navbar a.btn {
    height: 100px
}

.navbar .navbar-nav .nav-link {
    margin-right: 30px;
    padding: 25px 0;
    color: $dark;
    font-weight: 500;
    text-transform: uppercase;
    outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
    color: $primary;
}

.navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
}

@media (max-width: 991.98px) {
    .navbar .navbar-nav .nav-link  {
        margin-right: 0;
        padding: 10px 0;
    }

    .navbar .navbar-nav {
        border-top: 1px solid #EEEEEE;
    }
}

@media (min-width: 992px) {
    .navbar .nav-item .dropdown-menu {
        display: block;
        border: none;
        margin-top: 0;
        top: 150%;
        opacity: 0;
        visibility: hidden;
        transition: .5s;
    }

    .navbar .nav-item:hover .dropdown-menu {
        top: 100%;
        visibility: visible;
        transition: .5s;
        opacity: 1;
    }
}


/*** Header ***/
.carousel-caption {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: rgba(0, 0, 0, .75);
    z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
    width: 15%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    width: 3rem;
    height: 3rem;
    background-color: $primary;
    border: 10px solid $primary;
}

@media (max-width: 768px) {
    #header-carousel .carousel-item {
        position: relative;
        min-height: 450px;
    }
    
    #header-carousel .carousel-item img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.page-header {
    background: linear-gradient(rgba(0, 0, 0, .75), rgba(0, 0, 0, .75)), url(../img/graduados.jpg) center center no-repeat;
    background-size: cover;
}

.page-header-programas {
    background: linear-gradient(rgba(0, 0, 0, .75), rgba(0, 0, 0, .75)), url(../img/programas2.jpg)   no-repeat;
    background-size: cover;
}

.page-header-principal {
    background: linear-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)), url(../img/graduados.jpg) center center no-repeat;
    background-size: cover;
}

.breadcrumb-item+.breadcrumb-item::before {
    color: #999999;
}


/*** Facts ***/
@media (min-width: 991.98px) {
    .facts {
        position: relative;
        margin-top: -75px;
        z-index: 1;
    }
}


/*** Courses ***/
.courses {
    min-height: 100vh;
    background: linear-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)), url(../img/cetprolocal.jpg) center center no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.courses-item .courses-overlay {
    position: absolute;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .5);
    overflow: hidden;
    opacity: 0;
    transition: .5s;
}

.courses-item:hover .courses-overlay {
    height: 100%;
    opacity: 1;
}


/*** Team ***/
.team-items {
    margin: -.75rem;
}

.team-item {
    padding: .75rem;
}

.team-item::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    background: #FFFFFF;
    transition: .5s;
    z-index: -1;
}

.team-item:hover::after {
    height: 100%;
    background: $primary;
}

.team-item .team-social {
    position: absolute;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .75);
    overflow: hidden;
    opacity: 0;
    transition: .5s;
}

.team-item:hover .team-social {
    height: 100%;
    opacity: 1;
}


/*** Testimonial ***/
.testimonial-carousel .owl-dots {
    height: 40px;
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.testimonial-carousel .owl-dot {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    width: 20px;
    height: 20px;
    background: transparent;
    border: 2px solid $primary;
    transition: .5s;
}

.testimonial-carousel .owl-dot.active {
    width: 40px;
    height: 40px;
    background: $primary;
}

.testimonial-carousel .owl-item img {
    width: 150px;
    height: 150px;
}


/*** Footer ***/
.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: $light;
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
}

.footer .btn.btn-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: $light;
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    color: $primary;
    letter-spacing: 1px;
    box-shadow: none;
}

.copyright {
    background: #092139;
}

.copyright a {
    color: $primary;
}

.copyright a:hover {
    color: $light;
}

.contenido-parrafo{
    p{
        text-align: justify;
        line-height: 2.5rem;
    }
}

