.galleryContainer {
  width: 100%;
  height: 700px;
  max-width: 1000px;
  margin: auto;
  user-select: none;
  box-shadow: 0px 0px 3px 1px #00000078;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 0.4rem;
}

.galleryContainer .slideShowContainer {
  width: 100%;
  height: 90%;
  overflow: hidden;
  background-color: gainsboro;
  position: relative;
  border-radius: 0.4rem;
}

.galleryContainer .slideShowContainer #playPause {
  width: 32px;
  height: 32px;
  position: absolute;
  background-image: url(images/playPause.png);
  background-repeat: no-repeat;
  z-index: 5;
  background-size: cover;
  margin: 5px;
  cursor: pointer;
}

.galleryContainer .slideShowContainer #playPause:hover {
  opacity: 0.7;
}

.galleryContainer .slideShowContainer .imageHolder {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
}

.galleryContainer .slideShowContainer .imageHolder img {
  width: 100%;
  height: 100%;
}

.galleryContainer .slideShowContainer .imageHolder .captionText {
  display: none;
}

.galleryContainer .slideShowContainer .leftArrow, .galleryContainer .slideShowContainer .rightArrow {
  width: 50px;
  background: #00000036;
  position: absolute;
  left: 0;
  z-index: 1;
  transition: background 0.5s;
  height: 72px;
  top: 50%;
  transform: translateY(-50%);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.galleryContainer .slideShowContainer .rightArrow {
  left: auto;
  right: 0;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.galleryContainer .slideShowContainer .leftArrow:hover, .galleryContainer .slideShowContainer .rightArrow:hover {
  background: #000000a8;
  cursor: pointer;
}

.galleryContainer .arrow {
  display: inline-block;
  border: 3px solid white;
  width: 10px;
  height: 10px;
  border-left: none;
  border-bottom: none;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.galleryContainer .arrow.arrowLeft {
  transform: rotateZ(-135deg);
}

.galleryContainer .arrow.arrowRight {
  transform: rotateZ(45deg);
}

.galleryContainer .slideShowContainer > .captionTextHolder {
  position: absolute;
  bottom: 0;
  z-index: 1;
  color: white;
  font-family: sans-serif;
  font-size: 20px;
  text-align: center;
  width: 100%;
  background: #00000047;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
}

.galleryContainer .slideShowContainer > .captionTextHolder > .captionText {
  margin: 0;
}

.galleryContainer #dotsContainer {
  width: 100%;
  height: 10%;
  text-align: center;
  padding-top: 20px;
  box-sizing: border-box;
}

.galleryContainer #dotsContainer .dots {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-left: 5px;
  background-color: #bbb;
  cursor: pointer;
  transition: background-color 0.5s;
}

.galleryContainer #dotsContainer .dots:first-child {
  margin-left: 0;
}

.galleryContainer #dotsContainer .dots:hover, .galleryContainer #dotsContainer .dots.active {
  background-color: #717171;
}

.galleryContainer .moveLeftCurrentSlide {
  animation-name: moveLeftCurrent;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.galleryContainer .moveLeftNextSlide {
  animation-name: moveLeftNext;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@keyframes moveLeftCurrent {
  from {
    margin-left: 0;
    opacity: 1;
  }
  to {
    margin-left: -100%;
    opacity: 1;
  }
}
@keyframes moveLeftNext {
  from {
    margin-left: 100%;
    opacity: 1;
  }
  to {
    margin-left: 0%;
    opacity: 1;
  }
}
.galleryContainer .moveRightCurrentSlide {
  animation-name: moveRightCurrent;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.galleryContainer .moveRightPrevSlide {
  animation-name: moveRightPrev;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@keyframes moveRightCurrent {
  from {
    margin-left: 0;
    opacity: 1;
  }
  to {
    margin-left: 100%;
    opacity: 1;
  }
}
@keyframes moveRightPrev {
  from {
    margin-left: -100%;
    opacity: 1;
  }
  to {
    margin-left: 0%;
    opacity: 1;
  }
}
.slideTextFromBottom {
  animation-name: slideTextFromBottom;
  animation-duration: 0.7s;
  animation-timing-function: ease-out;
}

@keyframes slideTextFromBottom {
  from {
    opacity: 0;
    margin-top: 100px;
  }
  to {
    opacity: 1;
    margin-top: 0px;
  }
}
.slideTextFromTop {
  animation-name: slideTextFromTop;
  animation-duration: 0.7s;
  animation-timing-function: ease-out;
}

@keyframes slideTextFromTop {
  from {
    opacity: 0;
    margin-top: -100px;
  }
  to {
    opacity: 1;
    margin-top: 0px;
  }
}
.contador {
  color: #F3BD00;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0;
}

.counter-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 30px 50px;
}
.counter-container span {
  color: #0C2B4B;
  font-weight: 700;
}

.counter-container i {
  color: #0C2B4B;
}

.counter {
  font-size: 60px;
  margin-top: 10px;
}

@media (max-width: 580px) {
  body {
    flex-direction: column;
  }
}
.py-6 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.my-6 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 30px;
  z-index: 99;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  font-weight: 500;
  transition: 0.5s;
}

.btn.btn-primary,
.btn.btn-outline-primary:hover {
  color: #FFFFFF;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}

/*** Navbar ***/
.logo img {
  width: 15rem;
}

.navbar.sticky-top {
  top: -100px;
  transition: 0.5s;
}

.navbar .navbar-brand,
.navbar a.btn {
  height: 100px;
}

.navbar .navbar-nav .nav-link {
  margin-right: 30px;
  padding: 25px 0;
  color: #0C2B4B;
  font-weight: 500;
  text-transform: uppercase;
  outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: #F3BD00;
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav .nav-link {
    margin-right: 0;
    padding: 10px 0;
  }

  .navbar .navbar-nav {
    border-top: 1px solid #EEEEEE;
  }
}
@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: block;
    border: none;
    margin-top: 0;
    top: 150%;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
  }

  .navbar .nav-item:hover .dropdown-menu {
    top: 100%;
    visibility: visible;
    transition: 0.5s;
    opacity: 1;
  }
}
/*** Header ***/
.carousel-caption {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
  width: 15%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3rem;
  height: 3rem;
  background-color: #F3BD00;
  border: 10px solid #F3BD00;
}

@media (max-width: 768px) {
  #header-carousel .carousel-item {
    position: relative;
    min-height: 450px;
  }

  #header-carousel .carousel-item img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.page-header {
  background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(../img/graduados.jpg) center center no-repeat;
  background-size: cover;
}

.page-header-programas {
  background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(../img/programas2.jpg) no-repeat;
  background-size: cover;
}

.page-header-principal {
  background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url(../img/graduados.jpg) center center no-repeat;
  background-size: cover;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #999999;
}

/*** Facts ***/
@media (min-width: 991.98px) {
  .facts {
    position: relative;
    margin-top: -75px;
    z-index: 1;
  }
}
/*** Courses ***/
.courses {
  min-height: 100vh;
  background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url(../img/cetprolocal.jpg) center center no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.courses-item .courses-overlay {
  position: absolute;
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  opacity: 0;
  transition: 0.5s;
}

.courses-item:hover .courses-overlay {
  height: 100%;
  opacity: 1;
}

/*** Team ***/
.team-items {
  margin: -0.75rem;
}

.team-item {
  padding: 0.75rem;
}

.team-item::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  background: #FFFFFF;
  transition: 0.5s;
  z-index: -1;
}

.team-item:hover::after {
  height: 100%;
  background: #F3BD00;
}

.team-item .team-social {
  position: absolute;
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.75);
  overflow: hidden;
  opacity: 0;
  transition: 0.5s;
}

.team-item:hover .team-social {
  height: 100%;
  opacity: 1;
}

/*** Testimonial ***/
.testimonial-carousel .owl-dots {
  height: 40px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 20px;
  height: 20px;
  background: transparent;
  border: 2px solid #F3BD00;
  transition: 0.5s;
}

.testimonial-carousel .owl-dot.active {
  width: 40px;
  height: 40px;
  background: #F3BD00;
}

.testimonial-carousel .owl-item img {
  width: 150px;
  height: 150px;
}

/*** Footer ***/
.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: #F3F6F8;
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #F3F6F8;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  color: #F3BD00;
  letter-spacing: 1px;
  box-shadow: none;
}

.copyright {
  background: #092139;
}

.copyright a {
  color: #F3BD00;
}

.copyright a:hover {
  color: #F3F6F8;
}

.contenido-parrafo p {
  text-align: justify;
  line-height: 2.5rem;
}

.form-horizontal input {
  border-radius: 10px;
}

/* UI */
/* @import "layout/header";
@import "layout/footer";
@import "layout/navegacion";
@import "layout/iconos";
@import "layout/anuncios"; */