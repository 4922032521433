//@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');


// Fuentes
//$fuente_principal: 'Lato', sans-serif;

// Tamaño de Media Queries
$telefono: 480px;
$tablet: 768px;
$desktop: 1024px;

// Colores

//$negro: #000000;
//$amarillo: #E08709;;
//$verde: #71B100;
//$blanco: #FFFFFF;
//$gris: #e1e1e1;
//$grisOscuro: #333333;

$separacion: 5rem;

// Fuentes

$delgada: 300;
$regular : 400;
$bold: 700;
$black: 900;

$primary: #F3BD00;
$secondary: #757575;
$light: #F3F6F8;
$dark: #0C2B4B;